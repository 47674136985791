<template>
  <div class="main">
    <div class="position" v-for="data in positions" :key="data.index">
      <span>Latitude: {{data.latitude}} </span>
      <span>Longitude: {{data.longitude}} </span>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HelloWorld',
  data() {
    return {
      positions: []
    }

  },
  props: {
    msg: String,
  },
  async created(){
    this.position();
  },
  methods:{
    position: function (){
      navigator.geolocation.watchPosition((position) => {
        var coords = position.coords;
        this.positions.push(coords)
       })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
h3 {
  margin: 40px 0 0;
}
ul {
  list-style-type: none;
  padding: 0;
}
li {
  display: inline-block;
  margin: 0 10px;
}
a {
  color: #42b983;
}
</style>
